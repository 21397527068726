
  import {Component, Vue} from 'vue-property-decorator';
  import {_financeManage} from '@/utils/pageConfig/finance/_financeManage';
  import {Foundation} from '@/utils/Foundation';
  import API_FinanceManage from '@/utils/API/finance/FinanceManage';

  @Component({
    name: 'editFinance',
  })
  export default class extends Vue {
    pageConfig: any = _financeManage;
    isLoading: boolean = false;
    data: any = {};

    async show(data: any) {
      (this.$refs.editFinance as any).show();
      this.$nextTick(() => {
        (this.$refs.editFinanceForm as any).form = Foundation.deepClone(data);
        (this.$refs.editFinanceForm as any).$refs.form.clearValidate();
      });
    }

    hide() {
      (this.$refs.editFinance as any).hide();
    }

    async submitForm() {
      let form = (this.$refs.editFinanceForm as any).form;
      form.wxTotalRecharge = Foundation.toMoney(form._wxTotalRecharge);
      form = Foundation.resetParams(form);

      (this.$refs.editFinanceForm as any).$refs.form.validate(async (valid: any) => {
        if (valid) {
          if (this.isLoading) return;
          this.isLoading = true;
          try {
            await API_FinanceManage.editWxAccount(form);
            this.isLoading = false;
            this.$message.success('操作成功');
            this.hide();
            this.$emit('submitForm', form);
          } catch (e) {
            this.isLoading = false;
          }
        } else {
          return false;
        }
      });
    }
  }
