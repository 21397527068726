export const _financeManage = {
    //筛选
    search: [
        {
            type: 'el-select',
            label: '商户：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户',
                clearable: true
            },
            options: []
        },
        {
            type: 'el-date-picker',
            label: '时间：',
            value: 'date',
            props: {
                type: 'daterange',
                'range-separator': '至',
                'start-placeholder': '开始日期',
                'end-placeholder': '结束日期',
                'default-time': ['00:00:00', '23:59:59'],
                'value-format': "timestamp"
            }
        },
    ],
    //列表
    table: [
        {
            prop: 'merchantName',
            label: '商户名称'
        },
        {
            prop: '_totalRecharge',
            label: '充值金额'
        },
        {
            prop: '_totalRefund',
            label: '累计退款'
        },
        {
            prop: '_prHbAmount',
            label: '红包发放成功金额'
        },
        {
            prop: '_reconciliation',
            label: '对账余额'
        },
        {
            width: "220px",
            prop: '_remaining',
            label: '系统余额（预计可用天数）'
        },
        {
            prop: '_warningAmount',
            label: '预警阈值'
        },
        {
            prop: '_wxTotalRecharge',
            label: '微信商户号充值金额'
        },
        {
            prop: 'wxRemark',
            label: '备注'
        },
        {
            label: '操作',
            self: {
                body: 'operation'
            }
        }
    ],
    //编辑财务备注表单
    editFinanceForm: [
        {
            type: 'el-input',
            label: '微信商户号充值金额：',
            value: '_wxTotalRecharge',
            props: {
                clearable: true
            }
        },
        {
            type: 'el-input',
            label: '备注：',
            value: 'wxRemark',
            props: {
                type: 'textarea',
                rows: '4',
                clearable: true
            }
        }
    ],
};
