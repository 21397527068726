
    import {Component, Vue} from 'vue-property-decorator';
    import {_financeManage} from '@/utils/pageConfig/finance/_financeManage';
    import {Foundation} from '@/utils/Foundation';
    import API_MerchantList from "@/utils/API/merchant/MerchantList";
    import API_FinanceManage from '@/utils/API/finance/FinanceManage';
    import editFinance from "./components/editFinance.vue";

    @Component({
        components: {editFinance},
    })
    export default class FinanceManage extends Vue {
        /*表格相关*/
        pageConfig: any = _financeManage;
        tableData: any = [];
        total: number = 0;
        params: any = {
            current: 1,
            size: 20,
        };
        exportForm: any = {
            type: {
                tag: "ADMIN_FUND_MERCHANT_EXPORT",
                url: "/scheduler/api/v1/sch/export/exec/fund/merchant"
            },
            fileName: "财务总表"
        };

        mounted() {
            this.getAllMerchant();
            this.getTableData();
        }

        async getAllMerchant() {
            let options : any = [];
            let res : any = await API_MerchantList.getAllMerchant();
            res.map((e : any) => {
              options.push({label: e.coName, value: e.id});
            });

            this.pageConfig.search[0].options = options;
        }

        /*获取表格数据*/
        async getTableData() {
            let res: any = await API_FinanceManage.getFinanceManage(this.params);
            this.tableData = (res.records && res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._totalRecharge = Foundation.toYUAN(e.totalRecharge);
                    e._totalRefund = Foundation.toYUAN(e.totalRefund);
                    e._prHbAmount = Foundation.toYUAN(e.prHbAmount);
                    e._reconciliation = Foundation.toYUAN(e.totalRecharge - e.totalRefund - e.prHbAmount);
                    let remaining = e.totalRecharge - e.totalRefund - e.totalDed;
                    e._remaining = Foundation.toYUAN(remaining);
                    if(e.threeDayUsed > 0){
                      e._remaining += `（${parseInt((remaining / ( e.threeDayUsed / 3)).toFixed(2))}天）`;
                    }
                    e._warningAmount = Foundation.toYUAN(e.warningAmount);
                    e._wxTotalRecharge = Foundation.toYUAN(e.wxTotalRecharge);
                    return e
                })) || [];
            this.total = res.total;
        }

        summaryMethod(param: any){
            const { columns, data } = param;
            let sums: any = ['合计：', '**', '**', '**', '**', '', '', '**'];
            columns.forEach((column: any, index: any) => {
                if (['merchantName', '_remaining', '_warningAmount', 'wxRemark', undefined].includes(column.property)) {
                    return;
                }
                const values = data.map((item: any) => Number(item[column.property]));
                sums[index] = values.reduce((prev: any, curr: any) => {return prev + curr}, 0).toFixed(2);
            });
            return sums;
        }

        /*底部分页切换*/
        currentChange(pageNumber: number) {
            this.params.current = pageNumber;
            this.getTableData();
        }

        /*底部分页条数切换*/
        sizeChange(sizeNumber: number) {
            this.params.size = sizeNumber;
            this.getTableData();
        }

        /*查询*/
        handleSearch() {
            let query: any = Foundation.resetParams((this.$refs.searchForm as any).form, true);
            query = Foundation.resetDate(query)
            this.exportForm.condition = query;
            this.params = {
                current: 1,
                size: 20,
                ...query,
            };
            this.getTableData();
        }

        /*编辑*/
        handleEdit(data: any){
            (this.$refs.editFinance as any).show(data);
        }

        /*导出*/
        handleExport() {
            (this.$refs.exports as any).show(this.exportForm);
        }
    }
