import Request from "../Request";

export default class FinanceManage {
    public static baseSchUrl = '/scheduler/api/v1/sch/admin/fund/merchant';
    public static baseUrl = '/core/api/v1/admin/b/merchant';

    /*获取财务总表*/
    public static getFinanceManage(params: any) {
        return Request({
            url: `${this.baseSchUrl}/query`,
            method: "get",
            params
        });
    }

    /*编辑微信商户号充值金额*/
    public static editWxAccount(params: any) {
        return Request({
            url: `${this.baseUrl}/fund/account/wx/edit`,
            method: "get",
            params
        });
    }
}
